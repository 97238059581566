import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Avatar,
  useMediaQuery,
  useTheme,
  styled,
  InputAdornment,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Fab,
} from "@mui/material";
import Divider from '@mui/material/Divider';

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdSignalWifiOff } from "react-icons/md";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VideocamIcon from '@mui/icons-material/Videocam';
import MicIcon from '@mui/icons-material/Mic';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WhatsAppClockIcon from "./WhatsAppClockIcon";

import ChatScreen from "./Chatting_screen";
import SearchIcon from "@mui/icons-material/Search";

import io from "socket.io-client";
import { useParams } from "react-router-dom";
import { format, isToday, isThisWeek, isYesterday } from "date-fns"; // Importing date-fns for formatting time
import { hi, enUS, ar } from "date-fns/locale";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";

import { useTranslation } from "react-i18next";
import {
  openDB,
  addMessageToDB,
  getMessagesFromDB,
  deleteMessageFromDB,
} from "../utility/indexedDB";
import AddGroupMembersScreen from "./AddGroupMembersScreen";
import GroupInfoPage from "./GroupInfoPage ";

const ChatContainer = styled(Box)(({ theme }) => ({
  // padding: "0 20px",
  backgroundColor: "#f5f5f5",
  // borderRadius: "15px",
  height: "100vh",
  overflow: "hidden",

  "& .MuiOutlinedInput-root": {
    borderRadius: "8px", // Customize the border radius
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    height: "35px",
    paddingLeft: "10px",
    //   backgroundColor: '#202c33',
    color: "#8696a0",
    border: "none",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove the border
  },

  // Styling for the placeholder
  "& .MuiInputBase-input::placeholder": {
    color: "#8696a0", // Placeholder color
    opacity: 1, // Ensure the color applies fully
  },

  "& .imgbox": {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    padding: "10px",
  },
  "& .singleMsg": {
    padding: "21px 20px 22px 23px",
  },
  "& .upperChat": {
    padding: "14px 27px 0 22px",
  },
  "& .displaySpacebetween": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      gap: "5px",
    },
    "& img": {
      width: "60px",
      height: "60px",
    },
  },
}));

const MessageScreen = ({ language, initiateChatRoomData }) => {
  const [isGroupScreenVisible, setIsGroupScreenVisible] = useState(false);
  const [groupInfoPage, setgroupInfoPage] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatData, setChatData] = useState([]);
  const socketRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverId, setReceiverId] = useState("");
  const [userId, setUserId] = useState(
    initiateChatRoomData.senderDetails.userId
  );
  // const [userId, setUserId] = useState(
  //   useParams().id
  // );
  const [typingStatus, setTypingStatus] = useState(false);
  const [receiverDetails, setReceiverDetails] = useState("");
  const theme = useTheme();
  const [AppTheme, setAppTheme] = useState("light");
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);
  const [connect, setConnect] = useState(true);
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isXsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // Changed breakpoint to "md" for a wider large screen
  const ismdScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(AppTheme); // Default theme
  const [fontSizeDialogOpen, setFontSizeDialogOpen] = useState(false);
  const [fontSize, setFontSize] = useState("medium");
  const [selectedFontSize, setSelectedFontSize] = useState(fontSize);
  const msgRef = useRef(false);
  const [onlineMode, setOnlineMode] = useState(true);
  const userChatRef = useRef(false);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const chatRefs = useRef(null);
  const [profileImg, setProfileImg] = useState("");
  const [roomInitiateChat, setRoomInitiateChat] = useState(true);
  const [languageDialogOpen, setLanguageDialogOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default language
  const [chatType, setchatType] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [participants,setparticipants]=useState([])
  const socketUrl = useMemo(() => "https://node-chatsdk.mobiloitte.io/", []); // stagging
    // const socketUrl = useMemo(() => 'http://172.16.6.83:2138/', []);  //izhar local   
  const [mediaFileInput, setMediaFileInput] = useState(null);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [caption, setCaption] = useState("");
  const [changeUser, setChangeUser] = useState(false)

  const socketEvent = {
    initiateChat: "initiateUserChat",
    sendMessage: "userSendMessage",
    receiveMessage: "receiveUserMessage",
    userMessage: "getUserMessage",
  };
  const dbName = "ChatAppDB";
  const storeName = "unsentMessages";
  const db = useRef(null);
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(socketUrl, {
        transports: ["websocket"],
        secure: true,
        reconnect: true,
        reconnectionAttempts: 10, // Number of attempts to reconnect
        reconnectionDelay: 1000, // Delay between attempts
      });
    }

    const socket = socketRef.current;

    socket.on("connect", () => {
      console.log("Socket connected");
      initiateChat();
      // validateUserApp();
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on("reconnect", (attempt) => {
      console.log(`Socket reconnected after ${attempt} attempts`);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    socket.on("reconnect", (attempt) => {
      console.log(`Socket reconnected after ${attempt} attempts`);
    });

    socket.on("userChatList", (data) => {
      setChatData(data.userChatList);

      handleOnline();
    });

    socket.on("userChatInitiated", (data) => {
      console.log("romminndfnsdfnsd", roomInitiateChat);
      if (roomInitiateChat == true && chatRefs.current != true)
        console.log("romminndfnsdfnsd", roomInitiateChat);
      if (roomInitiateChat == true && chatRefs.current != true)
        setRoomInitiateChat(data.chatRoomId);

      validateUserApp();
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("reconnect");
      socket.off("userChatList");
      socket.off("userChatInitiated");
      disconnectSocket();
    };
  }, []);

   
  useEffect(() => {
    if (roomInitiateChat && chatData.length > 0) {
      console.log("selectedjkldsjfkjsdfk", roomInitiateChat);
      const selectedChat = chatData.filter(
        (chat) => chat.chatRoomId === roomInitiateChat
      );

      setRoomInitiateChat(null);
      console.log("jiortweiofnjkasdiopj", selectedChat);
      setSelectedRoomId(selectedChat[0]?.chatRoomId);
      setShowChat(true);
      setReceiverName(selectedChat[0]?.userDetails?.userName);
      setReceiverId(selectedChat[0]?.userDetails?.userId);
      setReceiverDetails(selectedChat[0]?.userDetails);
      setProfileImg(selectedChat[0]?.userDetails?.profileUrl);
      setchatType(selectedChat[0]?.chatType);
    }
  }, [chatData]);

  const initiateChat = () => {
    if (!socketRef.current) {
      console.error("Socket connection not established");
      return;
    }
    // const roomData = {
    //   appKey: "12345",
    //   senderDetails: {
    //     userName: "Ajay",
    //     countryCode: "1",
    //     mobileNumber: "0987654321",
    //     userId: "1001",
    //     email: "jane@example.com",
    //     profileUrl: "http://example.com/profile.jpg",
    //   },

    //   receiverDetails: {
    //     userName: "Larry Page",
    //     countryCode: "1",
    //     mobileNumber: "1234567890",
    //     userId: "1080",
    //     email: "larrypage@example.com",
    //     profileUrl: "http://example.com/profile.jpg",
    //   },
    // };
    socketRef.current.emit("initiateUserChat", initiateChatRoomData);
  };

  const validateUserApp = () => {
    if (!socketRef.current) {
      console.error("Socket connection not established");
      return;
    }
    console.log("validated app");
    const roomData = {
      appKey: "12345",
      userId: userId,
      appId: "7878",
    };

    socketRef.current.emit("validateUserApp", roomData, (response) => {
      if (response.error) {
        console.error("Validation failed:", response.error);
      } else {
        console.log("User validated:", response);
      }
    });
  };

  const disconnectSocket = () => {
    if (socketRef.current) {
      socketRef.current.off("connect");

      socketRef.current.off(socketEvent.receiveMessage);
      socketRef.current.off(socketEvent.userMessage);
      socketRef.current.disconnect();
      socketRef.current = null;
      console.log("Socket disconnected");
    }
  };

  const handleChatItemClick = (selectedChat) => {
    selectedRoomId !== selectedChat?.userDetails?.chatRoomId && setChangeUser(true)
    setSelectedRoomId(selectedChat?.userDetails?.chatRoomId);
    setSelectedRoomId(selectedChat?.chatRoomId);
    setShowChat(true);
    selectedChat.chatType === "ONE_TO_ONE"
      ? setReceiverName(selectedChat?.userDetails?.userName)
      : setReceiverName(selectedChat?.groupDetails?.groupName);
    selectedChat.chatType === "ONE_TO_ONE" &&
      setReceiverId(selectedChat?.userDetails?.userId);
    selectedChat.chatType === "ONE_TO_ONE" &&
      setReceiverDetails(selectedChat?.userDetails);
    selectedChat.chatType === "ONE_TO_ONE"
      ? setProfileImg(selectedChat?.userDetails?.profileUrl)
      : setProfileImg(selectedChat?.groupDetails?.imageUrl);
    console.log(
      "selectedChat?.chatTypeselectedChat?.chatType",
      selectedChat?.chatType
    );
    setchatType(selectedChat?.chatType);
    selectedChat.chatType !== "ONE_TO_ONE" && setparticipants(selectedChat.groupDetails.allUserDetails)
  };

  const groupSelected = (selectedChat) => {
    setSelectedRoomId(selectedChat?.chatRoomId);
    setShowChat(true);
    setReceiverName(selectedChat.groupName);
    setReceiverId("");
    setReceiverDetails("");
    setProfileImg(selectedChat?.imageUrl);
    setIsGroupScreenVisible(false);
    setchatType("GROUP");
    setparticipants(selectedChat.participants)
  };

  const getHindiTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "सुबह"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "दोपहर"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "शाम"; // Evening
    } else {
      return "रात"; // Night
    }
  };

  const convertToArabicNumerals = (number) => {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()

      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
      .join("");
  };


  const getArabicTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "صباحًا"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "ظهرًا"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "مساءً"; // Evening
    } else {
      return "ليلًا"; // Night
    }
  };


  const formatTimestamp = (timestamp) => {
    if (!timestamp) return
    const date = new Date(timestamp);


    if (isNaN(date)) {
      return "Invalid date";
    }


    // Determine the user's current language
    const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set


    // Select locale based on the language
    const locale =
      currentLanguage === "hi" ? hi : currentLanguage === "ar" ? ar : enUS;

    const hours = date.getHours();
    const minutes = date.getMinutes();


    if (isToday(date)) {
      // Format time and number localization based on the language
      const formattedTime = format(date, "h:mm", { locale });


      let timePeriod;
      if (currentLanguage === "hi") {
        timePeriod = getHindiTimePeriod(hours);
        return `${timePeriod} ${formattedTime} बजे`;
      } else if (currentLanguage === "ar") {
        timePeriod = getArabicTimePeriod(hours);


        // Convert numbers to Arabic format manually
        const arabicFormattedTime = `${convertToArabicNumerals(
          hours
        )}:${convertToArabicNumerals(minutes)}`;
        return `${arabicFormattedTime} ${timePeriod}`;
      } else {
        timePeriod = format(date, "a", { locale }).toLowerCase();
        return `${formattedTime}${timePeriod}`;
      }
    } else if (isYesterday(date)) {
      // Yesterday: Display appropriate terms in Hindi and Arabic
      return currentLanguage === "hi"
        ? "कल"
        : currentLanguage === "ar"
          ? "أمس"
          : "Yesterday";
    } else if (isThisWeek(date)) {
      // This week: Format as day of the week (e.g., "Monday" or "सोमवार")
      return format(date, "EEEE", { locale });
    } else {
      // Older messages: Format as dd/MM/yyyy and localize numbers in Arabic
      const formattedDate = format(date, "dd/MM/yyyy", { locale });
      if (currentLanguage === "ar") {
        const day = convertToArabicNumerals(date.getDate());
        const month = convertToArabicNumerals(date.getMonth() + 1);
        const year = convertToArabicNumerals(date.getFullYear());
        return `${day}/${month}/${year}`;
      }
      return formattedDate;
    }
  };

  const truncateText = (text, limit) => {
    if (text.length > limit) {
      return text.slice(0, limit) + "...";
    }
    return text;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleThemeDialogOpen = () => {
    setThemeDialogOpen(true);
    handleMenuClose();
  };
  const handleThemeDialogClose = (applyTheme = false) => {
    if (applyTheme) {
      setAppTheme(selectedTheme); // Apply the selected theme
    }
    setThemeDialogOpen(false);
  };

  const handleThemeChange = (event) => {
    setSelectedTheme(event.target.value);
  };

  const handleFontSizeDialogOpen = () => {
    setFontSizeDialogOpen(true);
    handleMenuClose();
  };

  const handleFontSizeDialogClose = (applyFontSize = false) => {
    if (applyFontSize) {
      setFontSize(selectedFontSize); // Apply the selected font size
    }
    setFontSizeDialogOpen(false);
  };

  const handleFontSizeChange = (event) => {
    setSelectedFontSize(event.target.value);
  };

  const handleOnline = async () => {
    openDB(dbName, storeName)
      .then((database) => {
        db.current = database;
      })
      .catch((error) => {
        console.error("Error opening database:", error);
      });
    console.log("socket checking ", socketRef.current);
    console.log("indexedDB connected with you");
    if (db.current) {
      msgRef.current = true;
      setMessages((prevMessageList) => {
        console.log("msg deleted sir", db);
        return prevMessageList.filter((msg) => msg.status !== "unsent");
      });
    }
  };

  useEffect(() => {
    if (msgRef.current === true) {
      msgRef.current = false;

      const sendmessages = async () => {
        console.log(
          "inside sendmessages sir how are you please tell me ",
          db.current
        );
        if (db.current) {
          console.log("indexedDB connected with you");
          const unsentMessages = await getMessagesFromDB(db.current, storeName);
          if (unsentMessages.length > 0) {
            const messageToSend = { ...unsentMessages[0] };
            delete messageToSend.status;
            socketRef.current.emit(socketEvent.sendMessage, messageToSend);
            await deleteMessageFromDB(
              db.current,
              storeName,
              unsentMessages[0].id
            );
          }
        }
      };
      console.log("sendmessges are here sir");
      sendmessages();
    }
  }, [messages, msgRef.current]);

  const changeLastMessageStatus = (data, type) => {
    let chatDataArray = chatData.map(item => {
      if (type == "send" && item.chatRoomId === data.chatRoomId) {
        item.lastMessageDetails = data
        return item
      } else if (type == "receive" && item.chatRoomId === data.chatRoomId && item.lastMessageDetails._id == data.messageId) {
        item.lastMessageDetails.messageStatus = data.messageStatus
        return item
      } else return item

    });
    if (chatDataArray.length > 0) {
      setChatData(chatDataArray)
    }
    data.chatRoomId === selectedRoomId &&      
          setMessages((prevMessageList) =>
            prevMessageList.map((msg) =>
              msg.messageId === data.messageId || msg._id === data.messageId
                ? { ...msg, messageStatus: data.messageStatus }
                : msg
            )
          );
  }

  let isUpdating = false
   function moveIdFirst(data, unreadMessagesCount = false) {
    isUpdating= true
    try {
      let itemToMove = chatData.find(item => item.chatRoomId === data.chatRoomId);
      const remainingItems = chatData.filter(item => item.chatRoomId !== data.chatRoomId);
      itemToMove.lastMessageDetails = data
      if (unreadMessagesCount && selectedRoomId != itemToMove.chatRoomId) {
        itemToMove.unreadMessagesCount = itemToMove.unreadMessagesCount + 1
        // console.log("itemToMove: ", itemToMove);
      }
      setChatData(itemToMove ? [itemToMove, ...remainingItems] : chatData)
      if (data.chatRoomId === selectedRoomId) {
        setMessages((prevMessageList) => [...prevMessageList, data]);

        socketRef.current.emit("userUpdateMessageStatus", {
          chatRoomId: selectedRoomId,
          userId,
          messageId: data.messageId,
          messageStatus:itemToMove.chatType==='GROUP' ? undefined:"READ",
        });
      }
    } catch (error) {
    }
    isUpdating = false
  }

  


 function changeTypeingStatus(data) {
  if(isUpdating) return
    try {
      
      let itemToMove = chatData.map((item) => {
        try {
          if (item.chatType =="ONE_TO_ONE" && item.chatRoomId === data.chatRoomId ) {
            item.userDetails.isOnline = data.onlineStatus
            item.userDetails.isTyping = data.typingStatus
          }
          return item
        } catch (error) {
          return item

        }

      })
      if (itemToMove.length > 0) {
        // chatArray = itemToMove
        setChatData(itemToMove)
      }

    } catch (error) {
    }
  }

  useEffect(() => {

    if (socketRef.current) {

      socketRef.current.on("sendersTypingAndOnlineStatus", (data) => {
       if(selectedRoomId==data.chatRoomId){ setTypingStatus(data)}
        console.log("Before changeTypeingStatus, chatData: ", chatData);
        changeTypeingStatus(data)
      });

      socketRef.current.on(socketEvent.receiveMessage, (data) => {
        moveIdFirst(data, true)
      });

      socketRef.current.on("userMessageStatusUpdated", (data) => {
        changeLastMessageStatus(data, "receive")
      });
      // Cleanup on unmount
      return () => {
        if (socketRef.current) {
          socketRef.current.off("sendersTypingAndOnlineStatus");
          socketRef.current.off(socketEvent.receiveMessage);
          socketRef.current.off("userMessageStatusUpdated");
        }
      };
    }
  }, [selectedRoomId,chatData,messages]);


  useEffect(() => {
    // Define the event handlers
    i18n.changeLanguage(language);

    const handleOnlineMode = () => {
      setOnlineMode(true);
      socketRef.current?.connect();
    };
    const handleOfflineMode = () => {
      setOnlineMode(false);
      chatRefs.current = true;
      socketRef.current?.disconnect();
    };

    !navigator.onLine && handleOfflineMode();
    // Add event listeners for online and offline
    window.addEventListener("online", handleOnlineMode);
    window.addEventListener("offline", handleOfflineMode);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener("online", handleOnlineMode);
      window.removeEventListener("offline", handleOfflineMode);
    };
  }, []);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleLanguageDialogClose = (applyLanguage) => {
    console.log("applyLanguage parameter:", applyLanguage);
    if (applyLanguage) {
      i18n.changeLanguage(selectedLanguage); // Apply the selected language
    }
    setLanguageDialogOpen(false);
  };

  const handleLanguageDialogOpen = () => {
    setLanguageDialogOpen(true);
    handleMenuClose();
  };

  const isTypingInArabic = (text) => {
    if (text === "") return true;

    // Regex to detect Arabic characters including extended ranges
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
  };

  const handleCreateGroup = () => {
    console.log("Create group clicked!");
    setIsGroupScreenVisible(true);
  };

  const handleCloseGroupScreen = () => {
    setIsGroupScreenVisible(false);
  };

  const handlesetgroupInfoPage = (value) => {
    setgroupInfoPage(value);
  }



  const handleOpenDialog = () => {
    console.log('handleOpenDialoghandleOpenDialog is open')
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };





  return (
    <ChatContainer>
      <Grid
        container
        sx={{ height: "auto", direction: i18n.language === "ar" ? "rtl" : "" }}
      >
        <Grid
          position={"relative"}
          item
          xs={12}
          sm={5}
          md={4}
          lg={3}
          sx={{
            width: '100%',
            boxSizing: 'border-box',

            display: isSmScreen ? (selectedRoomId ? "none" : "block") : "block",
            // padding: isSmScreen ? "0" : "0 10px 10px 0",
            height: "100vh",
            borderRight: "1px solid ",
            borderColor: AppTheme === "dark" ? "#222d34" : "#f0f2f5",
            // direction:i18n.language==='ar'?'rtl':'',
            // textAlign:'right'
          }}
        >
          {!groupInfoPage ? <>
            {!isGroupScreenVisible ? (
              <>
                <Box
                  sx={{
                    padding: "15px 12px 12px 12px",
                    boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.06)",
                    backgroundColor:
                      AppTheme === "dark" ? "#111b21" : "#ffffff",

                    height: "100vh",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: AppTheme === "dark" ? "#e9edef" : "#111B21",
                        fontSize: "22px",
                        paddingLeft: "10px",
                        fontWeight: "700",
                        "@media(max-width:767px)": {
                          fontSize: "22px !important",
                        },
                      }}
                    >
                      {t("chats")}
                    </Typography>

                    <IconButton
                      onClick={handleThemeDialogOpen}
                      sx={{
                        color: AppTheme === "dark" ? "#e9edef" : "#111B21",
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Box>

                  <Box mt={2}>
                    <TextField
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          backgroundColor:
                            AppTheme === "dark" ? "#202c33" : "#f0f2f5",
                          //   color: '#8696a0',
                        },
                        "& .MuiInputBase-input": {
                          color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", // Color for the input text
                          textAlign:
                            i18n.language === "ar" &&
                              isTypingInArabic(searchValue)
                              ? "right"
                              : "left", // Align text based on language
                          direction:
                            i18n.language === "ar" &&
                              isTypingInArabic(searchValue)
                              ? "rtl"
                              : "ltr",
                        },
                      }}
                      fullWidth
                      variant="outlined"
                      placeholder={t("search")}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: "#8696a0" }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>

                  {!onlineMode && (
                    <Box
                      mt={2}
                      sx={{
                        minHeight: "62px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor:
                          AppTheme === "dark" ? "#182229" : "#ffd279",
                        gap: "5px",
                      }}
                    >
                      <div>
                        <MdSignalWifiOff size={49} color="gray" />
                      </div>
                      <Box sx={{ padding: "15px 10px" }}>
                        <Typography
                          sx={{
                            fontWeight: "600",
                            fontSize: "16px",
                            color: AppTheme === "light" ? "#111b21" : "#e9edef",
                            lineHeight: "21px",
                          }}
                        >
                          Computer not connected
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: AppTheme === "light" ? "#111b21" : "#8696a0",
                            lineHeight: "19px",
                          }}
                        >
                          Make sure your computer has an active internet
                          connection
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    mt={onlineMode ? 2 : ""}
                    sx={{
                      height: "calc(100vh - 150px)",
                      maxHeight: "100vh",
                      overflowY: "auto",
                      paddingBottom: "20px",
                      scrollbarWidth: "thin",
                      scrollbarColor:
                        AppTheme === "dark" ? "#3b4348 #111b21" : " #111b21",
                    }}
                  >
                    {!isLoading &&
                      (chatData?.filter((chat) => {
                        if (!searchValue) return true;
                        return chat?.chatType === "ONE_TO_ONE"
                          ? chat?.userDetails?.userName
                          : chat?.groupDetails?.groupName
                            ?.toLowerCase()
                            ?.includes(searchValue.toLowerCase());
                      }).length === 0
                        ? onlineMode && (
                          <Typography
                            align="center"
                            sx={{
                              mt: 2,
                              color: AppTheme === "dark" ? "white" : "black",
                              fontSize: "14px",
                            }}
                          >
                            {t("userNotFound")}
                          </Typography>
                        )
                        : chatData
                          ?.filter((chat) => {
                            if (!searchValue) return true;
                            return chat?.chatType === "ONE_TO_ONE"
                              ? chat?.userDetails?.userName
                              : chat?.groupDetails?.groupName
                                ?.toLowerCase()
                                ?.includes(searchValue.toLowerCase());
                          })
                          .map((chat, index) => (

                            <Box

                              key={chat?.chatRoomId}
                              sx={{
                                // minHeight:'75px',
                                display: "flex",
                                alignItems: "center",
                                width: '100%',
                                boxSizing: 'border-box',
                                gap: "5px",
                                cursor: "pointer",

                                backgroundColor:
                                  selectedRoomId === chat?.chatRoomId
                                    ? AppTheme === "dark"
                                      ? "#2a3942"
                                      : "#f0f2f5"
                                    : "inherit",

                                color:
                                  selectedRoomId === chat?.chatRoomId
                                    ? AppTheme === "dark"
                                      ? "white"
                                      : "#111b21"
                                    : AppTheme === "dark"
                                      ? "inherit"
                                      : "white",
                                "&:hover": {
                                  backgroundColor:
                                    AppTheme === "dark"
                                      ? "#263136"
                                      : "#d0d3d7",
                                },
                              }}
                              onClick={() => handleChatItemClick(chat)}
                            >
                              <Avatar
                                src={
                                  chat?.chatType === "ONE_TO_ONE"
                                    ? chat?.userDetails?.profileUrl
                                    : chat?.groupDetails?.imageUrl
                                }
                                sx={{ width: 49, height: 49 }}

                              />
                              <Box
                                sx={{
                                  width: '100%',
                                  boxSizing: 'border-box',
                                  // flexGrow: 1,
                                  textAlign: "start",
                                  borderTop: index != 0 ? selectedRoomId !== chat?.chatRoomId ? "1px solid" : 0 : 0,
                                  borderColor:
                                    AppTheme === "dark"
                                      ? "#222d34"
                                      : "#f0f2f5",
                                  padding: "10px",
                                  paddingBottom: "15px",
                                  paddingTop: "15px",
                                  overflow: 'hidden',
                                  "&:hover": {
                                    borderTop: 0,
                                },
                                }}
                              >
                                <Box
                                  sx={{
                                    boxSizing: 'border-box',
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: '100%',
                                    gap: '10px'
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight="bold"
                                    sx={{
                                      direction: true ? 'rtl' : 'ltr', // Check if the language is Arabic
                                      textAlign: true ? 'right' : 'left', // Align text properly for Arabic
                                      color: AppTheme === "light" ? "#111b21" : "#e9edef",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      overflow: "hidden",
                                      direction: i18n.language === "ar" && (isTypingInArabic(chat?.chatType === "ONE_TO_ONE"
                                        ? chat?.userDetails?.userName + ""
                                        : chat?.groupDetails?.groupName + "") === true) ? 'rtl' : 'ltr',
                                      textAlign: i18n.language === "ar" && (isTypingInArabic(chat?.chatType === "ONE_TO_ONE"
                                        ? chat?.userDetails?.userName + ""
                                        : chat?.groupDetails?.groupName + "") === true) ? 'right' : 'left',
                                    }}
                                  >
                                    {chat?.chatType === "ONE_TO_ONE"
                                      ? chat?.userDetails?.userName
                                      : chat?.groupDetails?.groupName}
                                    {/* {truncateText(
    (chat.chatType === "ONE_TO_ONE"
      ? chat?.userDetails?.userName+""
      : chat?.groupDetails?.groupName+"") || "",
    isSmScreen ? 1000000 : 15000000
  )} */}
                                  </Typography>

                                  <Typography
                                    variant="caption"
                                    sx={{
                                      display: 'flex',
                                      // textAlign:'right',
                                      color:
                                        chat?.lastMessageDetails?.senderId !==
                                          userId &&
                                          chat?.unreadMessagesCount > 0
                                          ? "#00a884"
                                          : "#8696a0",
                                      fontSize: "12px",
                                      marginRight: "6px",
                                      fontWeight: "400",

                                    }}
                                  >
                                    {formatTimestamp(
                                      chat?.lastMessageDetails?.createdAt
                                    )}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginTop: "3px",
                                    width: '100%',
                                    boxSizing: 'border-box',
                                    overflow: "hidden",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: "14px",
                                      color: "#8696a0",
                                      wordWrap: "break-word",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {!chat?.userDetails?.isTyping &&
                                      chat?.lastMessageDetails?.senderId ===
                                      userId && (
                                        <IconButton
                                          size="small"
                                          sx={{
                                            padding: "0px 0.1rem 0px 0px",
                                          }}
                                        >
                                          {chat?.lastMessageDetails
                                            ?.messageStatus ===
                                            "DELIVERED" ? (
                                            <DoneAllIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: "#8696a0",
                                              }}
                                            />
                                          ) : chat?.lastMessageDetails
                                            ?.messageStatus === "READ" ? (
                                            <DoneAllIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: "#53bdeb",
                                              }}
                                            />
                                          ) : chat?.lastMessageDetails
                                            ?.messageStatus === "pending" ?
                                            <WhatsAppClockIcon
                                              sx={{
                                                fontSize: "1rem",
                                                color: AppTheme === "dark" ? "#e9edef" : "#3b4a54",
                                              }}
                                            /> : (
                                              <DoneIcon
                                                sx={{
                                                  fontSize: "1rem",
                                                  color: "#8696a0",
                                                }}
                                              />
                                            )}
                                        </IconButton>
                                      )}
                                    <Typography
                                      sx={{

                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color:
                                          chat?.lastMessageDetails
                                            ?.senderId !== userId &&
                                            chat?.unreadMessagesCount > 0
                                            ? AppTheme === "dark"
                                              ? "#e9edef"
                                              : "#3b4a54"
                                            : "inherit",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        // overflow: "hidden", 
                                        width: '100%',
                                        boxSizing: 'border-box',
                                        flexGrow: 1,
                                        display: 'flex',
                                        direction: i18n.language === "ar" && (isTypingInArabic(chat?.lastMessageDetails?.content) === true) ? 'rtl' : 'ltr',
                                        textAlign: i18n.language === "ar" && (isTypingInArabic(chat?.lastMessageDetails?.content) === true) ? 'right' : 'left',
                                      }}
                                    >
                                      {!chat?.userDetails?.isTyping ? (
                                        <>
                                          {chat?.lastMessageDetails?.messageType == 'VIDEO' ? <Box sx={{ display: "flex", alignItems: "center", color: "#8696A0" }}><VideocamIcon /> Video</Box > :
                                            chat?.lastMessageDetails?.messageType == 'IMAGE' ? <Box sx={{ display: "flex", alignItems: "center", color: "#8696A0" }}><CameraAltIcon sx={{ fontSize: "18px", marginRight: "3px" }} />  Photo</Box > :
                                              chat?.lastMessageDetails?.messageType == 'AUDIO' ? <Box sx={{ display: "flex", alignItems: "center", color: "#8696A0" }} ><MicIcon sx={{ fontSize: "22px", marginRight: "3px" }} /> Audio</Box> :
                                                chat?.lastMessageDetails?.messageType == 'FILE' ? <Box sx={{ display: "flex", alignItems: "center", color: "#8696A0" }} ><TextSnippetIcon sx={{ fontSize: "22px", marginRight: "3px" }} /> {chat?.lastMessageDetails?.fileName}</Box> :
                                                  truncateText(
                                                    chat?.lastMessageDetails?.content || "\u00A0",
                                                    isSmScreen ? 15 : ismdScreen ? 15 : 20
                                                  )
                                          }

                                        </>
                                      ) : (
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            fontFamily: `"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", Ubuntu, Cantarell, "Fira Sans", sans-serif`,
                                            fontWeight: "400",
                                            fontSize: "13px",
                                            lineHeight: "1.5",
                                            letterSpacing: "0.00938em",
                                            color: "#8696a0",
                                            // animation: "pulse 1.5s infinite"
                                            // padding: "0px 2px 2px 2px",
                                            // margin: "10px",
                                          }}
                                        >
                                          {t("typing")}
                                          <span className="dot">.</span>
                                          <span className="dot">.</span>
                                          <span className="dot">.</span>
                                        </Typography>
                                      )}
                                    </Typography>
                                  </Typography>
                                  {chat?.lastMessageDetails?.senderId !==
                                    userId && (
                                      <Badge
                                        badgeContent={
                                          chat?.unreadMessagesCount > 0
                                            ? chat.unreadMessagesCount
                                            : null
                                        } // Example badge count, replace with your logic
                                        // color="red"
                                        sx={{
                                          "& .MuiBadge-dot": {
                                            backgroundColor:
                                              chat?.unreadMessagesCount > 0
                                                ? "#00a884"
                                                : "transparent",
                                            animation:
                                              chat?.unreadMessagesCount > 0
                                                ? "pulse 2s infinite"
                                                : "none",
                                          },
                                          ".MuiBadge-badge": {
                                            right:
                                              i18n.language === "ar" ? -10 : 17,
                                            // top: 8,
                                            // minWidth: 18,
                                            // height: 18,
                                            padding: "4px",
                                            fontSize: "0.7rem",
                                            fontWeight: "bold",
                                            backgroundColor: "#00a884", // Customize badge color
                                            lineHeight: "0px",
                                          },
                                        }}
                                      ></Badge>
                                    )}
                                </Box>
                              </Box>
                            </Box>


                          )))}
                  </Box>
                </Box>

                {chatData.length > 0 && (
                  <Box
                    sx={{
                      position: "absolute", // Change to absolute positioning
                      bottom: "50px", // Space from the bottom
                      // right: '30px', // Space from the left (inside user list area)
                      left: i18n.language === "ar" ? "30px" : "auto",
                      right: i18n.language === "ar" ? "auto" : "30px",
                      zIndex: 1000, // Ensure it appears above other components
                    }}
                  >
                    {/* <Fab
                      color="primary" // You can change the color as needed
                      onClick={handleCreateGroup}
                      sx={{
                        // boxShadow: '3px 3px 8px black',
                        width: "50px", // Set width for square shape
                        height: "50px", // Set height for square shape
                        borderRadius: "50%", // Adjust border radius
                        padding: 0, // Remove default padding
                        minWidth: 0, // Prevent minimum width enforcement
                        "&:hover": {
                          backgroundColor: "primary.main", // Keep the hover color the same as the default
                        },
                      }}
                    >
                      <AddIcon /> 
                    </Fab> */}
                  </Box>
                )}
              </>
            ) : (

              <Box
                sx={{
                  boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.06)",
                  backgroundColor: AppTheme === "dark" ? "#111b21" : "#ffffff",
                  // borderRadius: "15px",
                  height: "100vh",
                }}
              >
                <AddGroupMembersScreen
                  onClose={handleCloseGroupScreen}
                  AppTheme={AppTheme}
                  socketRef={socketRef}
                  userId={userId}
                  groupSelected={groupSelected}
                />
              </Box>

            )}


          </>
            :
            <>

              <GroupInfoPage
                userId={userId}
                AppTheme={AppTheme}
                handlesetgroupInfoPage={handlesetgroupInfoPage}
                handleOpenDialog={handleOpenDialog}
                onClose={handleCloseDialog}
                handleCloseDialog={handleCloseDialog}
                socketRef={socketRef}
                chatRoomId={selectedRoomId}
              />

            </>
          }
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem sx={{ fontSize: "14px" }} onClick={handleThemeDialogOpen}>
              {t("theme")}
            </MenuItem>
            {/* <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={handleFontSizeDialogOpen}
            >
              Font Size
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} onClick={handleMenuClose}>
              Wallpaper
            </MenuItem> */}
            <MenuItem
              sx={{ fontSize: "14px" }}
              onClick={handleLanguageDialogOpen}
            >
              {t("appLanguage")}
            </MenuItem>
          </Menu>

          <Dialog
            open={languageDialogOpen}
            onClose={() => handleLanguageDialogClose(false)}
          >
            <DialogTitle>{t("Select_Language")}</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <FormControlLabel
                  value="en"
                  control={<Radio />}
                  label={t("english")}
                />
                <FormControlLabel
                  value="hi"
                  control={<Radio />}
                  label={t("hindi")}
                />
                <FormControlLabel
                  value="ar"
                  control={<Radio />}
                  label={t("arabic")}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleLanguageDialogClose(false)}>
                {t("Cancel")}
              </Button>
              <Button onClick={() => handleLanguageDialogClose(true)}>
                {t("OK")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={themeDialogOpen} onClose={handleThemeDialogClose}>
            <DialogTitle>{t("Select_Theme")}</DialogTitle>
            <DialogContent>
              <RadioGroup value={selectedTheme} onChange={handleThemeChange}>
                <FormControlLabel
                  value="light"
                  control={<Radio />}
                  label={t("light")}
                />
                <FormControlLabel
                  value="dark"
                  control={<Radio />}
                  label={t("dark")}
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleThemeDialogClose(false)}>
                {t("Cancel")}
              </Button>
              <Button onClick={() => handleThemeDialogClose(true)}>
                {t("OK")}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={fontSizeDialogOpen}
            onClose={() => handleFontSizeDialogClose(false)}
          >
            <DialogTitle>Select Font Size</DialogTitle>
            <DialogContent>
              <RadioGroup
                value={selectedFontSize}
                onChange={handleFontSizeChange}
              >
                <FormControlLabel
                  value="small"
                  control={<Radio />}
                  label="Small"
                />
                <FormControlLabel
                  value="medium"
                  control={<Radio />}
                  label="Medium"
                />
                <FormControlLabel
                  value="large"
                  control={<Radio />}
                  label="Large"
                />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleFontSizeDialogClose(false)}>
                Cancel
              </Button>
              <Button onClick={() => handleFontSizeDialogClose(true)}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          lg={9}
          sx={{
            display: isSmScreen ? (selectedRoomId ? "block" : "none") : "block",
            // padding: isSmScreen ? "0" : "0 0 10px 10px",
            height: "100vh",
            // direction:i18n.language==='ar'?'rtl':'',
          }}
        >
          {selectedRoomId ? (
            <ChatScreen
              open={true}
              handleClose={setSelectedRoomId}
              receiverId={receiverId}
              receiverName={receiverName}
              userid={userId}
              selectedRoomId={selectedRoomId}
              socketRef={socketRef}
              receiverDetails={receiverDetails}
              setMessages={setMessages}
              messages={messages}
              db={db.current}
              setMessage={setMessage}
              message={message}
              AppTheme={AppTheme}
              fontSize={fontSize}
              onlineMode={onlineMode}
              mediaFileInput={mediaFileInput}
              setMediaFileInput={setMediaFileInput}
              isCameraOn={isCameraOn}
              setIsCameraOn={setIsCameraOn}
              setCaption={setCaption}
              caption={caption}
              changeUser={changeUser}
              profileImg={profileImg}
              setChangeUser={setChangeUser}
              chatType={chatType}
              handleCreateGroup={handleCreateGroup}
              handlesetgroupInfoPage={handlesetgroupInfoPage}
              handleOpenDialog={handleOpenDialog}
              handleCloseDialog={handleCloseDialog}
              dialogOpen={dialogOpen}
              participants={participants}
              setReceiverName={setReceiverName}
              setProfileImg={setProfileImg}
              setparticipants={setparticipants}
              chatData={chatData}
              setChatData={setChatData}
              typingStatus ={typingStatus}
               setTypingStatus={setTypingStatus}
            />
          ) : (
            chatData.length > 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                sx={{
                  backgroundColor: AppTheme === "dark" ? "#222e35" : "#f0f2f5",
                  color: AppTheme === "dark" ? "white" : "black",
                }}
              >
                {onlineMode && (
                  <Typography variant="body1" sx={{ fontSize: "30px" }}>
                    {t("selectChat")}
                  </Typography>
                )}
              </Box>
            )
          )}
        </Grid>
      </Grid>
    </ChatContainer>
  );
};

export default MessageScreen;
