import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Paper,
  TextField,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { Edit, Check, CameraAlt, Close, PersonAdd } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import axios from "axios";
import { hi, enUS, ar } from "date-fns/locale";
import { format, isToday, isThisWeek, isYesterday } from "date-fns";

const GroupInfoPage = ({userId,handlesetgroupInfoPage, AppTheme,handleOpenDialog,chatRoomId,socketRef,onClose }) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [newGroupName, setNewGroupName] = useState();
  const [members,setGroupMembers]=useState([]);
  const { t, i18n } = useTranslation();
  const [profileHandling, setProfileHandling] = useState(false);
  const profileImgUrl=useRef(null);
  const [groupIcon, setGroupIcon] = useState(null);
  const [groupPage,setGroupPage]=useState(null)
  const [adminName,setAdminName] = useState('')
  const updateinfo=useRef(false);

  const handleNameEditToggle = () => {
   
    setIsEditing(!isEditing);
  };

  const handleNameChange = (e) => {
    setNewGroupName(e.target.value);
  };

  



  const handleNameSubmit = () => {
    updateinfo.current=true
    setIsEditing(false);
    
     
      let roomData={
        "appKey":'12345',
        chatRoomId:chatRoomId,
        "groupName":newGroupName.trim(),
        'imageUrl':groupIcon,
        // 'userDetails':userObjects
      }
      socketRef.current.emit('initiateGroupChatRoom',roomData,(response) => {
        if (response.error) {
          if(response.success===false){
            toast.error(response.message)
          }
          console.error("Validation failed:", response.error);
        } else {
          if(response.success===true){
           
            // toast.success(response.message)
          }
          console.log("User validated:", response);
        }
      })
    
    console.log("Updated Group Name:", newGroupName);
  };

  const handleImageUpload = async (e) => {
    if (!profileHandling) {
      const file = e.target?.files[0];
      setProfileHandling(true);
  
      const formData = new FormData();
      const id = toast.loading(t("uploading_media"));
      formData.append("file", file);
      
      try {
        const response = await axios({
          method: "POST",
          url: 'https://node-chatsdk.mobiloitte.io/api/v1/user/uploadFile',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
  
        if (response && response?.data?.responseCode === 200) {
          profileImgUrl.current = response?.data?.result;
          setGroupIcon(profileImgUrl.current);
          toast.success(t("media_uploaded_successfully"), { id });
          let roomData={
            "appKey":'12345',
            chatRoomId:chatRoomId,
            // "groupName":newGroupName.trim(),
            'imageUrl':profileImgUrl.current,
            // 'userDetails':userObjects
          }
          socketRef.current.emit('initiateGroupChatRoom',roomData,(response) => {
            if (response.error) {
              if(response.success===false){
                toast.error(response.message)
              }
              console.error("Validation failed:", response.error);
            } else {
              if(response.success===true){
               
                // toast.success(response.message)
              }
              console.log("User validated:", response);
            }
          })
        } else {
          toast.error(
            response?.data?.responseMessage || t("unable_to_upload_media"),
            { id }
          );
        }
        
      } catch (err) {
        toast.error(
          err?.response?.data?.responseMessage ||  t("error_occurred_during_upload"),
          { id }
        );
      } finally {
        
        setProfileHandling(false);
      }
    }
  };

 

  const isDarkTheme = AppTheme === 'dark';
  const isTypingInArabic = (text) => {
    if (text === "") return true;
    // Regex to detect Arabic characters including extended ranges
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
  };

  useEffect(()=>{
    if(socketRef.current){
      socketRef.current.emit('getGroupChatDetails',{chatRoomId:chatRoomId},(response) => {
        if (response.error) {
          console.error("Validation failed:", response.error);
        } else {
          console.log("User validated:", response);
        }
      });
      
      socketRef.current.on('groupChatDetails',(data)=>{
       
        setGroupPage(data.groupDetails)
       
      })
    }
     return ()=>{
      socketRef.current.off('groupChatDetails')
     }
  },[])

  useEffect(()=>{
   if(groupPage){
    setGroupIcon(groupPage.imageUrl)
    setNewGroupName(groupPage.groupName)
    setGroupMembers(groupPage.participants)
    
    
   }
  },[groupPage])

  useEffect(()=>{
        if(members.length>0){
          const adminUser= members.find(member=> member.userRole==='ADMIN')
          adminUser.userId===userId ? setAdminName(''):setAdminName(adminUser.userName);
        }
  },[members])


  const getHindiTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "सुबह"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "दोपहर"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "शाम"; // Evening
    } else {
      return "रात"; // Night
    }
  };

  // Utility function to convert numbers to Arabic numerals
  const convertToArabicNumerals = (number) => {
    const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return number
      .toString()
      .split("")
      .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
      .join("");
  };

  const getArabicTimePeriod = (hours) => {
    if (hours >= 5 && hours < 12) {
      return "صباحًا"; // Morning
    } else if (hours >= 12 && hours < 17) {
      return "ظهرًا"; // Afternoon
    } else if (hours >= 17 && hours < 20) {
      return "مساءً"; // Evening
    } else {
      return "ليلًا"; // Night
    }
  };

  const formatTimestamp = (timestamp) => {
    if(!timestamp)return;
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    // Determine the user's current language
    const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set

    // Select locale based on the language
    const locale =
      currentLanguage === "hi" ? hi : currentLanguage === "ar" ? ar : enUS; // Arabic Saudi Arabia locale for 'ar'


      const isToday = date.toDateString() === today.toDateString()
      const isYesterday = date.toDateString() === yesterday.toDateString()

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();

    // Format date and time with Arabic numerals if language is Arabic
    if (currentLanguage === "ar") {

     const formattedDate = `${convertToArabicNumerals(
        day
      )}/${convertToArabicNumerals(month)}/${convertToArabicNumerals(
        year
      )}`;
      const timePeriod = getArabicTimePeriod(hours);
       return formattedDate;
    } else if (currentLanguage === "hi") {
      const formattedDate = format(date, "d/M/yyyy", { locale });
      const timePeriod = getHindiTimePeriod(hours);
      return formattedDate;
    } else {
      // For English or other languages
      const formattedDate = format(date, "d/M/yyyy", { locale });
      const amPm = hours < 12 ? "AM" : "PM";
      return formattedDate;
    }
  };



  return (
    <Paper
      elevation={2}
      sx={{
        boxSizing: 'border-box',
        height: '100%',
        position: 'absolute',
        padding: "24px",
        backgroundColor: isDarkTheme ? '#111b21' : '#ffffff',
        color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary,
        width: "100%",
        borderRadius:'0px',
        // display: "flex", // Ensure the layout takes up full height
        // flexDirection: "column",
      }}
    >
      {/* Close Button */}
      <IconButton
        onClick={() => handlesetgroupInfoPage(false)}
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          zIndex: 1,
          color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary,
        }}
      >
        <Close />
      </IconButton>

      {/* Group Profile Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ position: "relative", display: "inline-block" }}>
          <Avatar
            src={groupIcon}
            
            sx={{
              width: 120,
              height: 120,
              [theme.breakpoints.down("sm")]: {
                width: 80,
                height: 80,
              },
            }}
          />
          <input
            accept="image/*"
            id="avatar-upload"
            type="file"
            style={{ display: "none" }}
            onChange={handleImageUpload}
          />
          <label htmlFor="avatar-upload">
            <IconButton
              component="span"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                backgroundColor: isDarkTheme ? '#333' : "white",
                color: isDarkTheme ? '#e0e0e0' : '#000',
                "&:hover": {
                  backgroundColor: isDarkTheme ? '#555' : "#f0f0f0",
                },
              }}
            >
              <CameraAlt />
            </IconButton>
          </label>
        </Box>
        {isEditing ? (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 ,width:'100%'}}>
           
           <TextField
  multiline
  value={newGroupName}
  onChange={handleNameChange}
  variant="standard"
  fullWidth
  sx={{
    textAlign:
      i18n.language === "ar" && isTypingInArabic(newGroupName) ? "right" : "left", // Align text based on language
    direction:
      i18n.language === "ar" && isTypingInArabic(newGroupName) ? "rtl" : "ltr",
    maxWidth: 300, // Set the same maxWidth for consistency
    '& .MuiInput-underline:before': {
      borderBottomColor: AppTheme === 'dark' ? 'rgba(192, 192, 192, 0.623)' : '#f0f2f5',
      borderBottomWidth: '2px',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: '#00A884'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#00A884'
    },
    '& .MuiInputLabel-root': {
      color: '#8696a0',
      fontSize: '14px',
      fontWeight: 600,
    },
    '& .MuiInputBase-root': {
      color: AppTheme === 'dark' ? '#e9edef' : '#111B21',
    },
    '& .MuiInputBase-input': {
      fontSize: '24px', // Set the font size to 24px
      lineHeight: '1.5', // Set the line height (adjust as needed)
    },
  }}
  InputProps={{
    endAdornment: (
      <IconButton onClick={handleNameSubmit} sx={{ color: isDarkTheme ? '#e0e0e0' : '#000' }}>
        <Check />
      </IconButton>
    ),
  }}
  inputProps={{
    maxLength: 100, // Set the maximum length of the input
  }}
/>



           
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography  sx={{ color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary,fontSize:'24px', textAlign: "center",lineHeight: "30px",
              wordBreak: "break-word", }}>
              {newGroupName}
            </Typography>
            <IconButton onClick={handleNameEditToggle} sx={{ color: isDarkTheme ? '#e0e0e0' : '#000' }}>
              <Edit />
            </IconButton>
          </Box>
        )}
      </Box>

      {/* Group Details Section */}
      <Box
        sx={{
          mb: 3,
          // backgroundColor: isDarkTheme ? '#222831' : theme.palette.background.default,
          borderRadius: "8px",
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          padding: "8px",
          
        }}
      >
        <Typography sx={{ color: isDarkTheme ? '#a3a3a3' : "#667781", fontSize: '14px', wordBreak: "break-word",  display:'flex',
              textAlign: "center", }}>
        {t('groupCreated', { user: adminName|| t('you')})} {formatTimestamp(groupPage?.createdAt)}
        
        </Typography>
        <Typography sx={{ color: isDarkTheme ? '#a3a3a3' : "#667781", fontSize: '14px' }}>
        {t('groupMembers', { count: members.length>0?members.length:'-' })}
        {/* {t('groupMembers')} */}
        </Typography>
      </Box>

      {/* Members List Section */}
      <Typography  sx={{ mb: 1, color: isDarkTheme ? '#e0e0e0' : theme.palette.text.primary,fontSize:'15px' }}>
      {t('getGroupMembersText')}
      </Typography>
      <Divider sx={{ mb: 2, backgroundColor: isDarkTheme ? '#444' : '#ccc' }} />

    
      <List
  sx={{
      boxSizing:'border-box',
    // maxHeight: "300px",
    // height:'auto',
     width: "100%", maxHeight: "calc(100vh - 400px)", overflowY: "auto",
     scrollbarWidth: "thin",
     scrollbarColor:
       AppTheme === "dark" ? "#3b4348 #111b21" : " #111b21",
    [theme.breakpoints.down("sm")]: {
      // maxHeight: "200px",
    },
    // backgroundColor: isDarkTheme ? '#1c1c1c' : '#fff',
  }}
>
  {/* Add Members Tab */}
  <ListItem onClick={handleOpenDialog} sx={{ border: `1px dashed ${isDarkTheme ? '#444' : '#ccc'}`, mb: 1, cursor: 'pointer',display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center', }}>
    <ListItemAvatar>
      <Avatar sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}>
        <PersonAdd />
      </Avatar>
    </ListItemAvatar>
    <ListItemText
      primary="Add Members"
      primaryTypographyProps={{ fontSize: "17px" }}
      sx={{ color: isDarkTheme ? '#e0e0e0' : '#000',textAlign: i18n.language === 'ar' ? 'right' : 'left', }}
    />
  </ListItem>

{members.map((member) => (
  <ListItem key={member._id}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <ListItemAvatar>
        <Avatar src={member.profileUrl}  />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography
            sx={{
              whiteSpace: 'nowrap', // Prevents text from wrapping
              overflow: 'hidden', // Hides overflowing text
              textOverflow: 'ellipsis', // Shows ellipsis at the end of overflowed text
              fontSize: '17px',
              direction: i18n.language === "ar" && (isTypingInArabic(member.userName)===true)? 'rtl' : 'ltr', 
              textAlign: i18n.language === "ar" && (isTypingInArabic(member.userName)===true)? 'right' : 'left',
              textAlign:i18n.language === "ar"?'end':'start'
            }}
             // This also ensures text doesn't wrap to the next line
          >
            {member.userName}
          </Typography>
        }
        primaryTypographyProps={{ fontSize: '17px' }}
        sx={{
          color: isDarkTheme ? '#e0e0e0' : '#000',
        }}
      />
    </Box>
  </ListItem>
))}



      </List>
    
    </Paper>
  );
};

export default GroupInfoPage;
