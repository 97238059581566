import React, { useRef, useEffect, useState } from "react";
import { FaRegCirclePause } from "react-icons/fa6";
import { FaRegDotCircle } from "react-icons/fa";
import { FaRegStopCircle } from "react-icons/fa";
import { Box, borderRadius, fontSize } from "@mui/system";
import { FaCamera } from "react-icons/fa";
import { FaPlayCircle } from "react-icons/fa";
import { FaPauseCircle } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { Square } from "@mui/icons-material";


import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  cameraIcon: { fontSize: "24px", 
    padding: "15px", 
    width: "25px", 
    height: '25px', 
    background: "#008069", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center", 
    borderRadius: "50%" 
    },

    displayFlexCenter : {
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
    },
   
    timer: { marginTop: "10px", 
      fontSize: "20px" ,
      color:"red"
    } ,
    cameraBox :{ position: "absolute", bottom: "20px", width: "100%", }, 
    sendIcon :{
      color: "#ffffff !important",
      marginLeft: "30px",
      position: 'absolute',
      bottom: '40px',
      // right: "50%",
      background: "#00A884",
      padding: "16px", // Adjust the padding for size
      fontSize: "24px", // Increase font size for icon
      borderRadius: "50%", // Keep it circular
    }

}));

const CameraRecorder = ({ isCameraOn, setIsCameraOn, AppTheme,

  StyledTextarea,
  caption,
  i18n,
  isTypingInArabic,
  message,
  handleCaptionChange,
  handleSend,
  fileType,
  setCaption,
  setMediaFileInput,
  mediaFileInput,
}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false); // New state for pause/resume
  const [recordedVideoURL, setRecordedVideoURL] = useState(null);
  const [capturedPhoto, setCapturedPhoto] = useState(null);
  const [isCameraReady, setIsCameraReady] = useState(false);
  const [timer, setTimer] = useState(0); // New state for the timer
  const timerRef = useRef(null);

  const onClose = () => {

    if (videoRef.current && videoRef.current.srcObject) {

      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop()); // Stop all tracks (camera off)
    }
    videoRef.current = null
    canvasRef.current = null
    setIsCameraOn(false)
    setMediaRecorder(null)
    setRecordedChunks([])
    setIsRecording(false)
    setIsPaused(false)
    setRecordedVideoURL(null)
    setCapturedPhoto(null)
    setIsCameraReady(false)
    setTimer(0)
    timerRef.current = null
    setCaption("")
    setMediaFileInput(null)

  }

  const offCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          setIsCameraReady(true);
        }
        const recorder = new MediaRecorder(stream, { mimeType: "video/mp4" });
        setMediaRecorder(recorder);
        recorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            setRecordedChunks((prev) => [...prev, event.data]);
          }
        };
      } catch (error) {
        console.error("Error accessing camera:", error);
      }
    };
    startCamera();
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);
  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1); // Increment the timer every second
    }, 1000);
  };
  const stopTimer = () => {
    clearInterval(timerRef.current); // Stop the timer
  };
  const startRecording = () => {
    if (mediaRecorder) {
      setRecordedChunks([]);
      mediaRecorder?.start();
      setIsRecording(true);
      setIsPaused(false);
      setTimer(0);
      startTimer(); // Start the timer
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder?.stop();
      setIsRecording(false);
      setIsPaused(false);
      offCamera()
    }
  };

  const pauseRecording = () => {
    if (isPaused) {
      mediaRecorder?.resume();
      startTimer();
    } else {
      mediaRecorder?.pause();
      stopTimer();
    }
    setIsPaused(!isPaused);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: "video/mp4" });
      const videoURL = URL.createObjectURL(blob);
      setRecordedVideoURL(videoURL);
      const file = new File([blob], "recorded-video.mp4", { type: "video/mp4" });
      setMediaFileInput(file)
    }
  }, [recordedChunks]);


  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    return new Blob([intArray], { type: mimeString });
  };


  const capturePhoto = () => {
    if (isCameraReady) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;


      context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const photo = canvas.toDataURL("image/png");
      setCapturedPhoto(photo);
      const blob = dataURLToBlob(photo);

      // Create a File object from Blob
      const file = new File([blob], "captured-photo.png", { type: "image/png" });

      // Set the media file input (e.g., for file upload or further processing)
      setMediaFileInput(file);

      offCamera()
    }
  };

  const classes = useStyles({ AppTheme });

  return (
    <>
      <Box
        style={{
          width: "92%",
          height: "96%",
          margin: "0 auto",
          padding: "20px",
          paddingRight: "40px",
          borderRadius: "10px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: 20,
            padding: "11px",
            color: "white !important",
            zIndex: "10000",
            backgroundColor: "white",
          }}
        >
          <IconButton
            onClick={onClose}

          >
            <CloseIcon />
          </IconButton>
        </Box>

        {!capturedPhoto && !recordedVideoURL && (
          <Box
            sx={{
              position: "relative",
              top: -20,
              color: "white !important",
            }}
          >
            <video
              ref={videoRef}
              style={{ width: "100%", }}
              autoPlay
              playsInline
              muted
            />
            <canvas ref={canvasRef} style={{ display: "none" }} />
          </Box>
        )}
        {(capturedPhoto || recordedVideoURL) && (
          <Box style={{
            marginTop: "50px", position: "relative",
            top: "10%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}>
            {capturedPhoto && <img
              src={capturedPhoto}
              alt="Captured"
              style={{ width: "100%", maxWidth: "600px" }}
            />}
            {recordedVideoURL &&
              <video
                src={recordedVideoURL}
                controls
                style={{ width: "100%", maxWidth: "600px", height: "auto", }}
              />

            }
            {/* {(recordedVideoURL || capturedPhoto) &&

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "14px 16px",
                  backgroundColor: "transparent",
                  width: "100%",
                  maxWidth: "600px",
                  justifyContent: "center"
                }}
              >

                <StyledTextarea
                  value={caption}
                  sx={{
                    paddingLeft: "20px",
                    background: AppTheme === "dark" ? "#2B3B45" : "#fffff",
                    color: AppTheme === "dark" ? "#3b4a54" : "#3b4a54",
                    direction:
                      i18n.language === "ar" && isTypingInArabic(message)
                        ? "rtl"
                        : "ltr", 
                    whiteSpace: "nowrap",  
                    overflow: "hidden",  
                    textOverflow: "ellipsis"
                  }}
                  onChange={handleCaptionChange}
                  minRows={1}
                  maxRows={5} 
                  placeholder="Add a caption"
                />

               
              </Box>

            } */}
          </Box>
        )}
      </Box>
     {(recordedVideoURL || capturedPhoto) && 
     <Box
     sx={{width:"100%",
         justifyContent:"center",
         display:"flex"
     }}
     >
 <IconButton
                  onClick={() =>{ 
                    handleSend(fileType,mediaFileInput,null)}}
                  className={classes.sendIcon}

                >
                  <SendOutlinedIcon />
                </IconButton>
     </Box>
    }
      {!capturedPhoto && !recordedVideoURL && isCameraReady && (
        <Box className={classes.cameraBox}>
          <Box
          className={classes.displayFlexCenter}
            style={{
              gap: "20px"
            }}
          >
            {!isRecording && (
              <Box
                onClick={capturePhoto}
                className={classes.cameraIcon}
              >
                <FaCamera />
              </Box>
            )}
            {!isRecording ? (
              <Box
                onClick={startRecording}
                className={classes.cameraIcon}
              >
                <FaRegDotCircle />
              </Box>
            ) : (
              <>
                <Box
                  onClick={stopRecording}
                 className={classes.cameraIcon}
                >
                  <FaRegStopCircle />
                </Box>
                <Box
                  onClick={pauseRecording}
                  className={classes.cameraIcon}
                >
                  {isPaused ? <FaPlayCircle /> : <FaPauseCircle />}
                </Box>
                {isRecording && (
                  <Box 
                  className ={classes.timer}
                  >
                    {formatTime(timer)}
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      )}


    </>
  );
};

export default CameraRecorder;
