import logo from "./logo.svg";
import "./App.css";
// import Chatting_screen from './Components/Chatting_screen'
import Chat from "./Components/chatIndex";
import { MyLibraryThemes } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import React from "react";
import './i18n';     
function App1({language,initiateChatRoomData}) {
  const MyLibraryThemeProvider = MyLibraryThemes();
  const {  i18n } = useTranslation();
  return (
    <div>   
      <ThemeProvider theme={MyLibraryThemeProvider}>
        <Router>
        <Toaster position={i18n.language === "ar" ? "top-left" : "top-right"} />
          <Routes>
          
            <Route path="/" element={<Chat language={language} initiateChatRoomData={initiateChatRoomData}/>} />

          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App1;
