import React, { useEffect, useState, useRef } from 'react';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaPhoneSlash, FaPhoneAlt } from 'react-icons/fa';
import './VideoChat.css'; // Custom CSS for WhatsApp-like UI

const VideoChat = ({ socketRef, userid, chatRoomid }) => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStreams, setRemoteStreams] = useState({});
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [roomId, setRoomId] = useState('');
  const localVideoRef = useRef(null);
  const peerConnectionsRef = useRef({});
  const callId = useRef(null); // Store call ID for tracking

  const userId = userid;
  const chatRoomId = chatRoomid;
  const socket = socketRef.current;

  const configuration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
  };

  const getUserMedia = async (isAudioCall) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: !isAudioCall,
        audio: true
      });
      localVideoRef.current.srcObject = stream; // Display local video
      setLocalStream(stream);
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.on('offer', handleIncomingCall);
      socket.on('answer', handleAnswer);
      socket.on('ice-candidate', handleICECandidate);
      socket.on('roomUsers', (users) => {
        console.log('Users in room:', users);
      });
    }

    return () => {
      if (socket) {
        socket.off('offer', handleIncomingCall);
        socket.off('answer', handleAnswer);
        socket.off('ice-candidate', handleICECandidate);
        socket.off('roomUsers');
      }
    };
  }, [socket]);

  const setupPeerConnection = (userId) => {
    const peerConnection = new RTCPeerConnection(configuration);
    peerConnectionsRef.current[userId] = peerConnection;

    if (localStream) {
      localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));
    }

    peerConnection.ontrack = (event) => {
      setRemoteStreams(prev => ({ ...prev, [userId]: event.streams[0] }));
    };

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit('ice-candidate', { candidate: event.candidate, userId, chatRoomId }, (response) => {
          if (response.success) {
            console.log('ice-candidate', response);
          } else {
            console.error('Call failed:', response.message);
          }
        });
      }
    };

    return peerConnection;
  };

  const handleIncomingCall = async ({ offer, chatRoomId, userId, callId: incomingCallId }) => {
    setIsRinging(true);
    callId.current = incomingCallId;
    const peerConnection = setupPeerConnection(userId);
    await peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);
    socket.emit('answer', { answer, chatRoomId, userId, callId: callId.current });
    setCallStarted(true);
  };

  const handleAnswer = async ({ answer, userId }) => {
    const peerConnection = peerConnectionsRef.current[userId];
    if (peerConnection) {
      await peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
    }
  };

  const handleICECandidate = async ({ candidate, from }) => {
    if (peerConnectionsRef.current[from]) {
      await peerConnectionsRef.current[from].addIceCandidate(new RTCIceCandidate(candidate));
    }
  };

  const startCall = async (isAudioCall) => {
    if (!localStream) {
      console.error('Local stream not available. Cannot start call.');
      return;
    }
  
    socket.emit('joinRoom', { roomId: chatRoomId, userId }); // Join the room
  
    const peerConnection = setupPeerConnection(userId);
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
  
    // Debugging log for emitted offer
    console.log('Emitting offer:', { offer, chatRoomId, userId, callId: callId.current });
  
    socket.emit('offer', { offer, chatRoomId, userId, callId: callId.current }, (response) => {
      if (response.success) {
        console.log('Call started:', response);
      } else {
        console.error('Call failed:', response.message);
      }
    });
  };
  
  // When receiving the offer
  socket.on('offer', ({ offer, chatRoomId, userId, callId }) => {
    console.log('Received offer:', { offer, chatRoomId, userId, callId });
    handleIncomingCall({ offer, chatRoomId, userId, callId });
  });
  

  const toggleMute = () => {
    if (localStream) {
      localStream.getAudioTracks()[0].enabled = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleVideo = () => {
    if (localStream) {
      localStream.getVideoTracks()[0].enabled = !isVideoOff;
      setIsVideoOff(!isVideoOff);
    }
  };

  const endCall = () => {
    socket.emit('endCall', { callId: callId.current }, (response) => {
      if (response.success) {
        console.log('Call ended:', response);
      } else {
        console.error('Call failed:', response.message);
      }
    });
    localStream.getTracks().forEach(track => track.stop());
    setLocalStream(null);
    setRemoteStreams({});
    setCallStarted(false);
    setIsRinging(false);
  };

  const acceptCall = async () => {
    if (!window.incomingOffer) {
      console.error('No incoming offer to accept.');
      return;
    }
    
    setIsRinging(false); // Stop ringing
    await getUserMedia(false); // Assume video call on accept
    
    const peerConnection = setupPeerConnection(userId);
    await peerConnection.setRemoteDescription(new RTCSessionDescription(window.incomingOffer)); // Ensure incomingOffer exists
    
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);
    
    socket.emit('answer', { answer, chatRoomId, userId, callId: callId.current }, (response) => {
      if (response.success) {
        console.log('Answer sent:', response);
      } else {
        console.error('Answer failed:', response.message);
      }
    });
    
    setCallStarted(true); // Mark call as started
  };
  
  const rejectCall = () => {
    setIsRinging(false); // Stop ringing
    socket.emit('callRejected', { callId: callId.current, chatRoomId, userId }, (response) => {
      if (response.success) {
        console.log('Call rejected', response);
      } else {
        console.error('Call failed:', response.message);
      }
    });
  };

  return (
    <div className="video-chat-container">
      <div className="local-video-container">
        <video ref={localVideoRef} autoPlay muted className="local-video" />
      </div>

      {Object.keys(remoteStreams).map(userId => (
        <div className="remote-video-container" key={userId}>
          <video autoPlay className="remote-video" ref={video => {
            if (video) video.srcObject = remoteStreams[userId];
          }} />
        </div>
      ))}

      {isRinging && (
        <div className="incoming-call-popup">
          <p>Incoming Call...</p>
          <button className="accept-button" onClick={acceptCall}>
            <FaPhoneAlt /> Accept
          </button>
          <button className="reject-button" onClick={rejectCall}>
            <FaPhoneSlash /> Reject
          </button>
        </div>
      )}

      {callStarted && (
        <div className="call-info">
          <p className="call-status">In Call...</p>
        </div>
      )}

      {callStarted && (
        <div className="call-controls">
          <button className="control-button" onClick={toggleMute}>
            {isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
          </button>
          {!isVideoOff && (
            <button className="control-button" onClick={toggleVideo}>
              {isVideoOff ? <FaVideoSlash /> : <FaVideo />}
            </button>
          )}
          <button className="control-button hangup-button" onClick={endCall}>
            <FaPhoneSlash />
          </button>
        </div>
      )}

      {!callStarted && (
        <div className="start-call-container">
          <input
            type="text"
            placeholder="Room ID"
            value={roomId}
            onChange={e => setRoomId(e.target.value)}
          />
          <button className="start-call-button" onClick={() => { getUserMedia(false); startCall(false); }}>
            Start Video Call
          </button>
          <button className="start-call-button" onClick={() => { getUserMedia(true); startCall(true); }}>
            Start Audio Call
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoChat;
