import React, { useState } from "react";
import { Box, IconButton, CircularProgress, Menu, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IoIosDocument } from "react-icons/io";
import { MdPhotoLibrary } from "react-icons/md";
import { FaCamera } from "react-icons/fa";

const MediaFile = ({
    mediaFileInput,
    AppTheme,
    handleSend,
    fileType,
    onClose,
    mediaType,
    fileSize,
    MediaPreview,
    setMediaFileInput

})=>{

  const [isFileSend,setIsFileSend] = useState(false)
    return (
        <Box
        sx={{
          position: "relative",
          top: "0px",
          background: AppTheme === "dark" ? "#101A20" : "#E9EDEF",
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >

        <Box
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            display: "flex",
            width: "100%",
            backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Initial background color
            "&:hover": {
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important",
            },
            "&:active": {
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important",
            },
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{

              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Initial background color
              "&:hover": {
                backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Ensure hover background stays the same
              },
              "&:active": {
                backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Ensure active background stays the same

              },
              color: "#8696A0"
            }}
          >


            <CloseIcon />


          </IconButton>

          <Box sx={{
            color: AppTheme === "dark" ? "#B3BFC5 !important" : "#424C51",
            flex: "grow1",
            width: "100%",
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace: "nowrap",  // Ensure it stays on one line
            overflow: "hidden",    // Hide overflow text
            textOverflow: "ellipsis" // Add ellipsis
          }}>
            {mediaFileInput.name}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: 'absolute',
            bottom: '60px',
          }}
        >

          <IconButton
            onClick={() =>{ 
            
                if(!isFileSend){
                  setIsFileSend(true)
                  handleSend(fileType,mediaFileInput,null)
                }
              
              
            }}
            sx={{
              color: "#ffffff !important",
              marginLeft: "30px",
              // position: 'absolute',
              // bottom: '40px',
              // right: "50px",
              background: "#00A884",
              padding: "16px", 
              fontSize: "24px", 
              borderRadius: "50%", 
            }}
          >
            <SendOutlinedIcon sx={{ fontSize: "inherit" }} /> {/* Inherit size from IconButton */}
          </IconButton>
        </Box>

        {/* Media container to center content */}
        {mediaType == 'Photo' && <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            maxHeight: "100%", // Limit height
            maxWidth: "80%", // Limit width
            overflow: "hidden", // Hide overflow
          }}
        >
          <MediaPreview mediaFileInput={mediaFileInput} />
        </Box>}

        {mediaType == 'Document' && <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
            maxHeight: "80%", // Limit height
            maxWidth: "80%", // Limit width
            overflow: "hidden", // Hide overflow
          }}
        >
          <Box sx={{ background: AppTheme === "dark" ? "#111B21" : "#F0F2F5", fontSize: "170px", borderRadius: "16px", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}><IoIosDocument />
            <Box sx={{ fontSize: "30px", borderRadius: "16px", padding: "20px", color: "#B3BFC5" }}>No previdew available</Box>
            <Box sx={{ fontSize: "20px", borderRadius: "16px", padding: "20px", color: "#B3BFC5" }}>{fileSize}</Box>
          </Box>


        </Box>}


      </Box>
      );

}

export default MediaFile;