import React, { useEffect, useRef, useState } from "react";
import { Box, Paper, Typography, IconButton, Dialog, DialogContent, MenuItem, Menu, CircularProgress } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import { format, isToday, isThisWeek, isYesterday } from "date-fns"; // Import your format function
import WhatsAppClockIcon from "./WhatsAppClockIcon"; // Adjust the path to your component
import { hi, enUS, ar } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";
import { borderRadius, maxWidth } from "@mui/system";
import { FaFile } from "react-icons/fa6";
import { MdDownloadForOffline } from "react-icons/md";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import WavesurferPlayer from '@wavesurfer/react'
import GroupInfoCard from "./GroupInfoCard ";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";






const Message = React.memo(
  ({ msg, userId, AppTheme, fontSize }) => {

    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [showFullMessage, setShowFullMessage] = React.useState(false); // State to track if full message is shown
    const [loading, setLoading] = useState(true);

    const getHindiTimePeriod = (hours) => {
      if (hours >= 5 && hours < 12) {
        return 'सुबह'; // Morning
      } else if (hours >= 12 && hours < 17) {
        return 'दोपहर'; // Afternoon
      } else if (hours >= 17 && hours < 20) {
        return 'शाम'; // Evening
      } else {
        return 'रात'; // Night
      }
    };

    const [fileType, setFileType] = useState(null)
    const [fileSize, setFileSize] = useState(null)
    // Utility function to convert numbers to Arabic numerals
    const convertToArabicNumerals = (number) => {
      const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
      return number
        .toString()
        .split('')
        .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
        .join('');
    };

    const getArabicTimePeriod = (hours) => {
      if (hours >= 5 && hours < 12) {
        return 'صباحًا'; // Morning
      } else if (hours >= 12 && hours < 17) {
        return 'ظهرًا'; // Afternoon
      } else if (hours >= 17 && hours < 20) {
        return 'مساءً'; // Evening
      } else {
        return 'ليلًا'; // Night
      }
    };

    const formatTimestamp = (timestamp) => {
      if (!timestamp) return;
      const date = new Date(timestamp);

      // Determine the user's current language
      const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set

      // Select locale based on the language
      const locale =
        currentLanguage === "hi" ? hi : currentLanguage === "ar" ? ar : enUS; // Arabic Saudi Arabia locale for 'ar'

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are 0-indexed
      const year = date.getFullYear();

      // Format date and time with Arabic numerals if language is Arabic
      if (currentLanguage === "ar") {
        const formattedDate = ` ${convertToArabicNumerals(hours)}:${convertToArabicNumerals(
          minutes
        )}`;
        const timePeriod = getArabicTimePeriod(hours);
        return `${formattedDate} ${timePeriod}`;
      } else if (currentLanguage === "hi") {
        const formattedDate = format(date, "h:mm", { locale });
        const timePeriod = getHindiTimePeriod(hours);
        return `${formattedDate} ${timePeriod}`;
      } else {
        // For English or other languages
        const formattedDate = format(date, "h:mm", { locale });
        const amPm = hours < 12 ? "AM" : "PM";
        return `${formattedDate} ${amPm.toLowerCase()}`;
      }
    };


    const toggleMessageVisibility = () => {
      setShowFullMessage((prev) => !prev);
    };


    const renderMessageContent = () => {
      const trimmedMessage = msg?.content?.trim();
      if (trimmedMessage?.length > 500 && !showFullMessage) {
        return (
          <>
            {trimmedMessage.substring(0, 500)}...{" "}
            <span
              onClick={toggleMessageVisibility}
              style={{ color: "#53BDEB", cursor: "pointer" }}
            >
              {t("Read_more")}
            </span>
          </>
        );
      }
      return trimmedMessage;
    };
    const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
    const [openDialogVideo, setOpenDialogVideo] = useState(false); // State to control dialog visibility

    const handleOpenDialog = () => {
      setOpenDialog(true);
    };

    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
    const handleOpenDialogVideo = () => {
      setOpenDialogVideo(true);
    };

    const handleCloseDialogVideo = () => {
      setOpenDialogVideo(false);
    };

    const [imgDimensions, setImgDimensions] = useState({
      width: 350, // Set initial width
      height: 200, // Set initial height
    });
    const [videoDimensions, setVideoDimensions] = useState({
      width: 350, // Set initial width
      height: 200, // Set initial height
    });


    const handleDownload = async (msg) => {
      console.log("msg: ", msg);
      try {
        const fileUrl = msg.content; // URL of the file
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // Determine the correct file extension based on the mime type
        const fileType = blob.type;
        let extension = '';

        // Handle different file types based on MIME type
        if (fileType.startsWith('image/')) {
          extension = 'jpg'; // Default for images
        } else if (fileType.startsWith('video/')) {
          extension = 'mp4'; // Default for videos
        } else if (fileType.startsWith('audio/')) {
          extension = 'mp3'; // Default for audio files
        } else {
          // For other files like documents, you can parse the URL or fallback
          const fileNameParts = msg.fileName ? msg.fileName.split('.') : ['downloaded_file'];
          extension = fileNameParts.length > 1 ? fileNameParts.pop() : 'txt'; // Default to text
        }

        // Set the file name with the correct extension
        link.setAttribute('download', msg.fileName || `downloaded_file.${extension}`);

        // Append and trigger download
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Clean up
      } catch (error) {
        console.error('Error downloading the file:', error);
      }
    };




    const getFileDetails = async (url) => {
      try {
        // Parse the URL to get the file name
        const fileName = url.split('/').pop();
        // Extract the file type from the file name
        const fileTypes = fileName.split('.').pop();

        // Fetch the file to get its size
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Get the file size in bytes
        const fileSizeInBytes = Number(response.headers.get('content-length'));

        const fileSizes = fileSizeInBytes < 1024
          ? `${fileSizeInBytes} bytes`
          : fileSizeInBytes < 1048576
            ? `${(fileSizeInBytes / 1024).toFixed(2)} KB`
            : `${(fileSizeInBytes / 1048576).toFixed(2)} MB`;
        setFileType(fileTypes)
        console.log("fileTypes: ", fileTypes);
        setFileSize(fileSizes)
        console.log("fileSizes: ", fileSizes);
        console.log("nice", { fileTypes, fileSizes });
        return
      } catch (error) {
        // setError(error.message);
        return "";
      }
    };
    useEffect(() => {

      if (msg.messageType == "FILE") {

        getFileDetails(msg.content);
      }
    }, [msg.content]);

    const [wavesurfer, setWavesurfer] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)

    const onReady = (ws) => {
      setWavesurfer(ws)
      setIsPlaying(false)
    }

    const onPlayPause = () => {
      wavesurfer && wavesurfer.playPause()
    }
    const [duration, setDuration] = useState('00:00');
    const videoRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleCloseFile = () => {
      setAnchorEl(null);
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: msg.senderId === userId ? "flex-end" : "flex-start",
          mb: 1,
        }}
      >
        <Paper
          elevation={2}
          sx={{
            padding: "6px 7px 0px 9px",
            maxWidth: "70%",
            wordBreak: "break-word",
            backgroundColor:
              msg.senderId === userId
                ? AppTheme === "dark"
                  ? "#005c4b"
                  : "#D9FDD3"
                : AppTheme === "dark"
                  ? "#202C33"
                  : "#FFFFFF",
            borderRadius:
              msg.senderId !== userId ? "0 8px 8px 8px" : "8px 0px 8px 8px",
            overflow: "hidden",
          }}
        >
          {msg.messageType == "MESSAGE" && <Typography
            variant="body1"
            sx={{
              fontSize:
                fontSize === "medium"
                  ? "14.2px"
                  : fontSize === "small"
                    ? "12px"
                    : "16px",
              color: AppTheme === "dark" ? "#e9edef" : "#111B21",
              whiteSpace: "pre-wrap", // Preserve spaces and line breaks
              wordWrap: "break-word", // Handle long words gracefully
            }}
          >
            {renderMessageContent()}

          </Typography>}


          {
            msg.messageType === "FILE" &&
            (



              <Box
                sx={{
                  width: "cal(100% - 10px)",
                  height: "60px",
                  zIndex: "10001",

                  backgroundColor:
                    msg.senderId === userId
                      ? AppTheme === "dark"
                        ? "#025144"
                        : "#D1F4CC"
                      : AppTheme === "dark"
                        ? "#1D282F"
                        : "#F5F6F6",
                  color:
                    msg.senderId === userId
                      ? AppTheme === "dark"
                        ? "#79909B"
                        : "#79909B"
                      : AppTheme === "dark"
                        ? "#79909B"
                        : "#79909B"
                  ,
                  // color:
                  // AppTheme === "dark"
                  //       ? "#005c4b"
                  //       : "#79909B"
                  //    ,
                  // background: AppTheme == 'dark' ? "#025144" : "#D1F4CC",
                  display: "flex", alignItems: "center", gap: '10px',
                  paddingRight: "10px",
                  paddingLeft: "4px",
                  fontSize: "27px",
                  borderRadius: "5px"

                }}


              >

                <Box
                  sx={{ width: "30px" }}
                >
                  <FaFile style={{ fontSize: '27px' }} />
                </Box>

                <Box
                  sx={{

                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: 'calc(100% -50px) !important',
                    fontSize: "14px",
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: "hidden",
                  }}
                >

                  <Box onClick={() => handleDownload(msg)} style={{
                    flexGrow: "1",

                    color:
                      msg.senderId === userId
                        ? AppTheme === "dark"
                          ? "#E8ECEE"
                          : "#28303a"
                        : AppTheme === "dark"
                          ? "#E8ECEE"
                          : "#28303a"
                    ,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }} >{msg.fileName} <br />  {fileType && fileSize && <span style={{
                    color: AppTheme === "dark"
                      ? "#FFFFFF99" : "rgb(105 116 106)", fontSize: ".9em"
                  }}>{msg.fileType || fileType}• {fileSize}</span>}
                  </Box>

                </Box>

                {/* <Box sx={{ fontSize: "29px" ,width:'30px'}}>  <MdDownloadForOffline  onClick={() => handleDownload(msg)} /> </Box> */}

              </Box>




            )}

          {msg.messageType === "IMAGE" && (
            <Box
              sx={{
                width: `${imgDimensions.width}px`,
                height: `${imgDimensions.height}px`,
                maxWidth: "min(350px,100%)",
                maxHeight: "350px",
                borderRadius: "8px",
                position: "relative",
                overflow: "hidden",
                backgroundColor: "#f0f0f0",
                '&:hover .dropdown-icon': {
                  opacity: 1,
                },
                cursor: "pointer",
              }}
            >
              <Box
                className="dropdown-icon"
                onClick={(event) => (setAnchorEl(event.currentTarget))}
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  background: 'transparent',
                  opacity: 0,
                  transition: 'opacity 0.3s ease-in-out',
                  zIndex: 1000,
                  color: "#fff",
                  width: "30px",
                  height: "30px"
                }}
              >
                <IoIosArrowDropdownCircle size={24} />
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseFile}
                PaperProps={{
                  sx: {
                    boxShadow: "none !important",
                    width: "180px !important",
                    backdropFilter: "none !important",
                    backgroundColor: AppTheme === "dark" ? "#182229 !important" : "#fff !important",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => {
                  handleDownload(msg)
                  setAnchorEl(null);
                }}>
                  <Box sx={{ color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", }}>Download</Box>
                </MenuItem>
              </Menu>

              {/* Show the placeholder image if loading */}
              {(loading) && msg.thumbnail && (
                <>
                  <Box
                    component="img"
                    src={msg.thumbnail} // Add your placeholder image path
                    alt="Placeholder"
                    sx={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  {/* Loader for pending message status */}
                  {msg.messageStatus === 'pending' && (
                    <CircularProgress
                      size="1.5rem"
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 1000,
                      }}
                    />
                  )}
                </>
              )}


              {/* Main image */}
              <Box
                component="img"
                src={msg.content}
                alt="Sent image"
                onLoad={(e) => {
                  const imgElement = e.target;

                  if (imgElement.naturalWidth > 350) {
                    let newHeigth = (imgDimensions.height / imgDimensions.width) * 350
                    setImgDimensions({
                      width: 350, // Set initial width
                      height: 200,
                    })
                  }

                  setLoading(false); // Set loading to false when the image is loaded
                }}
                onClick={handleOpenDialog}
                sx={{
                  display: loading ? "none" : "block", // Hide the main image while loading
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Box>
          )}
          {msg.messageType === "VIDEO" && (
            <Box
              sx={{
                width: `${videoDimensions.width}px`,
                height: `${videoDimensions.height}px`,
                maxWidth: 'min(350px, 100%)',
                maxHeight: '350px',
                borderRadius: '8px',
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#f0f0f0',
                cursor: 'pointer',
                '&:hover .dropdown-icon': {
                  opacity: 1,
                },
              }}
            >
              {/* Dropdown Icon */}
              <Box
                className="dropdown-icon"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                sx={{
                  position: 'absolute',
                  top: '8px',
                  right: '8px',
                  background: 'transparent',
                  opacity: 0,
                  transition: 'opacity 0.3s ease-in-out',
                  zIndex: 1000,
                  color: "#fff",
                  width: "30px",
                  height: "30px",
                }}
              >
                <IoIosArrowDropdownCircle size={24} />
              </Box>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseFile}
                PaperProps={{
                  sx: {
                    boxShadow: "none !important",
                    width: "180px !important",
                    backdropFilter: "none !important",
                    backgroundColor: AppTheme === "dark" ? "#182229 !important" : "#fff !important",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => {
                  handleDownload(msg)
                  setAnchorEl(null);
                }} >
                  <Box sx={{ color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", }}  >Download</Box>
                </MenuItem>
              </Menu>

              {/* Placeholder image shown while video is loading */}
              {loading && msg.thumbnail && (
                <>
                  <Box
                    component="img"
                    src={msg.thumbnail} // Use your thumbnail or placeholder image here
                    alt="Video Placeholder"
                    sx={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />

                </>
              )}

              {/* Video Component */}
              <Box
                component="video"
                src={msg.content}
                ref={videoRef}
                onLoadedMetadata={(e) => {
                  const videoElement = e.target;
                  const videoWidth = videoElement.videoWidth;
                  const videoHeight = videoElement.videoHeight;

                  if (videoWidth > 350) {
                    const newHeight = (videoHeight / videoWidth) * 350;
                    setVideoDimensions({
                      width: 350,
                      height: 200,
                    });
                  }

                  const durationInSeconds = Math.floor(videoElement.duration);
                  const minutes = String(Math.floor(durationInSeconds / 60)).padStart(2, '0');
                  const seconds = String(durationInSeconds % 60).padStart(2, '0');
                  setDuration(`${minutes}:${seconds}`);

                  // setLoading(false); // Hide the placeholder when video is fully loaded
                }}
                onClick={handleOpenDialogVideo}
                sx={{
                  display: loading ? "none" : "block", // Hide video while loading
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}

              />

              {/* Play Icon */}
              {msg.messageStatus !== 'pending' ? <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  color: "#ffffff",
                  borderRadius: '50%',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  },
                }}
                onClick={handleOpenDialogVideo}
              >
                <PlayArrowIcon fontSize="1.5rem" />
              </IconButton> :
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    borderRadius: '50%',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                    },
                  }}
                  onClick={handleOpenDialogVideo}
                >
                  <CircularProgress size="1.5rem" />
                </IconButton>



              }

              {/* Duration Display */}
              <Typography
                variant="body2"
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '10px',
                  color: 'white',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: "11px"
                }}
              >
                {duration}
              </Typography>
            </Box>
          )}
          {msg.messageType === "AUDIO" && (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      position: "relative",
      paddingTop: "12px",
      '&:hover .dropdown-icon': { // Show dropdown icon on hover
        opacity: 1,
      },
    }}
  >
    {/* Dropdown icon for audio */}
    <Box
      className="dropdown-icon"
      onClick={(event) => setAnchorEl(event.currentTarget)}
      sx={{
        position: 'absolute',
        top: '0px',
        right: '0px',
        background: 'transparent',
        opacity: 0, // Initially hidden
        transition: 'opacity 0.3s ease-in-out',
        zIndex: 1000,
        color: "#fff",
        width: "30px",
        height: "30px",
        cursor: "pointer",
      }}
    >
      <RiArrowDropDownLine size={24} style={{ background: 'transparent',color:AppTheme=='dark'?"#7A8185":'#9EABB3' }} />
    </Box>

    {/* Download Menu */}
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleCloseFile}
      PaperProps={{
        sx: {
          boxShadow: "none !important",
          width: "180px !important",
          backdropFilter: "none !important",
          backgroundColor: AppTheme === "dark" ? "#182229 !important" : "#fff !important",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem onClick={() => { handleDownload(msg); setAnchorEl(null); }}>
        <Box sx={{ color: AppTheme === "dark" ? "#e9edef" : "#3b4a54", }}>Download</Box>
      </MenuItem>
    </Menu>

    {/* Play/Pause Button */}
    <Box
      onClick={onPlayPause}
      sx={{
        color: msg.senderId === userId
          ? AppTheme === "dark"
            ? "#ffffff"
            : "#B0CEAE"
          : AppTheme === "dark"
            ? "#ffffff"
            : "#D1D2D3",
        marginRight: "10px",
      }}
    >
      {!isPlaying ? <PlayArrowIcon /> : <PauseIcon />}
    </Box>

    {/* Audio Waveform */}
    <Box sx={{ flexGrow: "1", width: "200px" }}>
      <WavesurferPlayer
        width="100%"
        height={30}
        waveColor={msg.senderId === userId
          ? AppTheme === "dark"
            ? "#4D8D81"
            : "#B0CEAE"
          : AppTheme === "dark"
            ? "#636B70"
            : "#D1D2D3"}
        url={msg.content}
        onReady={onReady}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
    </Box>
  </Box>
)}


          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            maxWidth="lg"
            fullWidth // Makes dialog responsive
            sx={{
              '& .MuiDialog-paper': {
                margin: 0, // Removes extra margin around the dialog
                width: 'auto', // Dialog takes full width of screen
                maxHeight: '100%', // Max height is the screen height
              }
            }}
          >
            <DialogContent
              sx={{
                position: "relative",
                padding: 0,
                backgroundColor: "black", // Dark background to focus on the image
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: "90vh", // Limit the height to the viewport height minus some padding
                overflowY: "auto", // Scrollbar for vertical overflow
              }}
            >
              {/* Close Button */}
              <IconButton
                onClick={handleCloseDialog}
                sx={{ position: "absolute", top: 10, right: 10, color: "white" }}
              >
                <CloseIcon />
              </IconButton>

              {/* Container to handle image scaling and overflow */}
              <Box
                sx={{
                  maxWidth: "100%",
                  maxHeight: "90vh", // Limit the container height to the viewport height
                  overflow: "hidden", // Ensure no overflow from the container
                  display: "flex", // Center the image
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Full-sized Responsive Image */}
                <Box
                  component="img"
                  src={msg.content}
                  alt="Full image"
                  sx={{
                    maxWidth: "100%", // Responsive to the screen width
                    maxHeight: "100%", // Limit to container height, maintaining aspect ratio
                    objectFit: "contain", // Maintain aspect ratio
                  }}
                />
              </Box>
            </DialogContent>
          </Dialog>


          <Dialog
            open={openDialogVideo}
            onClose={handleCloseDialogVideo}
            maxWidth="lg"
            fullWidth // Makes dialog responsive
            sx={{
              '& .MuiDialog-paper': {
                margin: 0, // Removes extra margin around the dialog
                width: 'auto', // Dialog takes full width of screen
                maxHeight: '100%', // Max height is the screen height
              }
            }}
          >
            <DialogContent
              sx={{
                position: "relative",
                padding: 0,
                backgroundColor: "black", // Dark background to focus on the video
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: "90vh", // Limit the height to the viewport height minus some padding
                overflowY: "auto", // Scrollbar for vertical overflow
              }}
            >
              {/* Close Button */}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleCloseDialogVideo();
                }}
                sx={{ position: "absolute", top: 10, right: 10, color: "white", zIndex: "10000" }}
              >
                <CloseIcon />
              </IconButton>

              {/* Container to handle video scaling and overflow */}
              <Box
                sx={{
                  maxWidth: "100%",
                  maxHeight: "90vh", // Limit the container height to the viewport height
                  overflow: "hidden", // Ensure no overflow from the container
                  display: "flex", // Center the video
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* Full-sized Responsive Video */}
                <Box
                  component="video"
                  src={msg.content}
                  controls
                  autoPlay
                  sx={{
                    maxWidth: "100%", // Responsive to the screen width
                    maxHeight: "100%", // Limit to container height, maintaining aspect ratio
                    objectFit: "contain", // Maintain aspect ratio
                  }}
                />
              </Box>
            </DialogContent>
          </Dialog>

          <Typography
            variant="caption"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // mt: 0.5,
              color: "#8696a0",
              fontSize: "11px",
            }}
          >
            {msg.senderId === userId ? msg.messageSentAt ? formatTimestamp(msg.messageSentAt) : formatTimestamp(msg.createdAt) : formatTimestamp(msg.createdAt)}
            {msg.senderId === userId &&

              (msg._id || msg.messageId || navigator.onLine ? (
                <IconButton
                  size="small"
                  sx={{ ml: 0.5, padding: "0px 0.1rem 0px 0px" }}
                >
                  {msg.messageStatus === "DELIVERED" ? (
                    <DoneAllIcon sx={{ fontSize: "1rem", color: "#8696a0" }} />

                  ) : msg.messageStatus === "READ" ? (
                    <DoneAllIcon sx={{ fontSize: "1rem", color: "#53bdeb" }} />
                  ) : msg.messageStatus === "pending" ?
                    <WhatsAppClockIcon
                      sx={{ fontSize: "1rem", color: "#8696a0" }}
                    /> :
                    (
                      <DoneIcon sx={{ fontSize: "1rem", color: "#8696a0" }} />
                    )}
                </IconButton>
              ) : (
                msg.status === 'unsent' && <IconButton
                  size="small"
                  sx={{ ml: 0.5, padding: "0px 0.1rem 0px 0px" }}
                >
                  <WhatsAppClockIcon
                    sx={{ fontSize: "1rem", color: "#8696a0" }}
                  />
                </IconButton>
              ))}
          </Typography>
        </Paper>
      </Box>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison logic
    return (
      prevProps.msg.content === nextProps.msg.content &&
      prevProps.msg.messageStatus === nextProps.msg.messageStatus &&
      prevProps.msg.createdAt === nextProps.msg.createdAt &&
      prevProps.msg.senderId === nextProps.msg.senderId &&
      prevProps.userId === nextProps.userId &&
      prevProps.AppTheme === nextProps.AppTheme &&
      prevProps.fontSize === nextProps.fontSize
    );
  }
);







const MessagesList = React.memo(
  ({
    messages,
    userId,
    AppTheme,
    fontSize,
    handleScroll,
    messagesEndRef,
    ismdScreenup,
    endMessageRef,
    onlineMode,
    selectedRoomId,
    chatType,
    handleCreateGroup,
    handleOpenDialog,
    handlesetgroupInfoPage,
    participants,
    profileImg,
    page,
    totalPages
  }) => {
    const [currentDate, setCurrentDate] = React.useState("");
    const { t } = useTranslation();
    const { i18n } = useTranslation();



    const handleScrollWithDate = (event) => {
      const container = event.target;
      const scrollTop = container.scrollTop;
      let visibleDate = "";

      // Find the currently visible date
      messages.forEach((msg, index) => {
        const messageDate = msg.createdAt

        // Identify the element corresponding to this message
        const messageElement = document.getElementById(`message-${index}`);
        if (messageElement) {
          const { offsetTop } = messageElement;
          if (scrollTop >= offsetTop - 40) {
            visibleDate = messageDate;
          }
        }
      });


      if (event.target.scrollTop === 0) {
        setCurrentDate('')
      }
      else if (visibleDate !== currentDate) {
        setCurrentDate(visibleDate);
      }

      ; // Adjust the timeout duration as needed


      // Call the original scroll handler if any
      if (handleScroll) {
        handleScroll(event);

      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      if (date.toDateString() === today.toDateString()) {
        return "Today";
      } else if (date.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else {
        return format(date, "d/M/yyyy");

      }
    };





    const getHindiTimePeriod = (hours) => {
      if (hours >= 5 && hours < 12) {
        return "सुबह"; // Morning
      } else if (hours >= 12 && hours < 17) {
        return "दोपहर"; // Afternoon
      } else if (hours >= 17 && hours < 20) {
        return "शाम"; // Evening
      } else {
        return "रात"; // Night
      }
    };

    // Utility function to convert numbers to Arabic numerals
    const convertToArabicNumerals = (number) => {
      const arabicDigits = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return number
        .toString()
        .split("")
        .map((digit) => (/\d/.test(digit) ? arabicDigits[digit] : digit))
        .join("");
    };

    const getArabicTimePeriod = (hours) => {
      if (hours >= 5 && hours < 12) {
        return "صباحًا"; // Morning
      } else if (hours >= 12 && hours < 17) {
        return "ظهرًا"; // Afternoon
      } else if (hours >= 17 && hours < 20) {
        return "مساءً"; // Evening
      } else {
        return "ليلًا"; // Night
      }
    };

    const formatTimestamp = (timestamp) => {
      if (!timestamp) return;
      const date = new Date(timestamp);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      // Determine the user's current language
      const currentLanguage = i18n.language || "en"; // Default to 'en' if no language is set

      // Select locale based on the language
      const locale =
        currentLanguage === "hi" ? hi : currentLanguage === "ar" ? ar : enUS; // Arabic Saudi Arabia locale for 'ar'


      const isToday = date.toDateString() === today.toDateString()
      const isYesterday = date.toDateString() === yesterday.toDateString()

      const hours = date.getHours();
      const minutes = date.getMinutes();
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are 0-indexed
      const year = date.getFullYear();

      // Format date and time with Arabic numerals if language is Arabic
      if (currentLanguage === "ar") {

        const formattedDate = `${convertToArabicNumerals(
          day
        )}/${convertToArabicNumerals(month)}/${convertToArabicNumerals(
          year
        )}`;
        const timePeriod = getArabicTimePeriod(hours);
        return isToday ? "اليوم" : isYesterday ? "أمس" : `${formattedDate}`;
      } else if (currentLanguage === "hi") {
        const formattedDate = format(date, "d/M/yyyy", { locale });
        const timePeriod = getHindiTimePeriod(hours);
        return isToday ? "आज" : isYesterday ? "कल" : `${formattedDate}`;
      } else {
        // For English or other languages
        const formattedDate = format(date, "d/M/yyyy", { locale });
        const amPm = hours < 12 ? "AM" : "PM";
        return isToday ? "Today" : isYesterday ? "Yesterday" : `${formattedDate}`;
      }
    };







    return (

      <Box
        ref={endMessageRef}
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "scroll",
          backgroundColor: AppTheme === "dark" ? "#0b141a" : "#efeae2",
          scrollbarWidth: "thin",
          scrollbarColor: AppTheme === "dark" ? "#3b4348 #111b21" : "#111b21",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          px: ismdScreenup ? "66px" : "16px",
          py: 1,
          position: "relative", // Make the container relative for positioning
        }}
        onScroll={handleScrollWithDate}
      >
        {currentDate && (
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 10,
              backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
              padding: "5px 10px",
              textAlign: "center",
              borderRadius: "10px",
              margin: "10px auto",
              width: "fit-content",

            }}
          >
            <Typography
              sx={{
                fontSize: "12.5px",

                color: "#8696a0",
              }}
            >
              {formatTimestamp(currentDate)}
            </Typography>
          </Box>
        )}

        {chatType === 'GROUP' && (totalPages==0 ||totalPages===page) && <Box sx={{ display: 'flex', justifyContent: 'center' }}><GroupInfoCard 
        profileImg={profileImg}
         AppTheme={AppTheme} 
         handleCreateGroup={handleCreateGroup}
          handleOpenDialog={handleOpenDialog}
           handlesetgroupInfoPage={handlesetgroupInfoPage}
            participants={participants}
             userId={userId}
             /></Box>}

        {/* <StickyHeader currentDate={currentDate} AppTheme={AppTheme} />    */}
        {(!onlineMode &&
          messages.length !== 0 &&
          messages[0].chatRoomId === selectedRoomId) ||
          onlineMode ? (
          messages.map((msg, index) => (
            <div key={msg._id || index} id={`message-${index}`}>
              {/* Display the date separator if it's the first message of the day */}
              {(index === 0 ||
                new Date(msg.createdAt).toLocaleDateString() !==
                new Date(messages[index - 1].createdAt).toLocaleDateString()) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      my: 1,
                    }}
                  >
                    <Paper
                      elevation={2}
                      sx={{
                        padding: "5px 10px",
                        backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
                        color: "#8696a0",
                        fontSize: "12.5px",
                        borderRadius: "8px",
                      }}
                    >
                      {formatTimestamp(msg.createdAt)}
                    </Paper>
                  </Box>
                )}
              <Message
                key={msg._id || index}
                msg={msg}
                userId={userId}
                AppTheme={AppTheme}
                fontSize={fontSize}
              />
            </div>
          ))
        )

          : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px 12px 6px",
                  maxWidth: "auto",
                  fontSize: "12.5px",
                  height: "auto",
                  wordBreak: "break-word",
                  color: AppTheme === "dark" ? "#e9edef" : "#111B21",
                  backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
                }}
              >
                Unable to load conversation
              </Paper>
            </Box>
          )}

        <div ref={messagesEndRef} />
      </Box>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.messages === nextProps.messages &&
      prevProps.AppTheme === nextProps.AppTheme &&
      prevProps.fontSize === nextProps.fontSize &&
      prevProps.handleScroll === nextProps.handleScroll &&
      prevProps.messagesEndRef === nextProps.messagesEndRef &&
      prevProps.endMessageRef === nextProps.endMessageRef &&
      prevProps.ismdScreenup === nextProps.ismdScreenup &&
      prevProps.userId === nextProps.userId &&
      prevProps.profileImg === nextProps.profileImg &&
      prevProps.handleCreateGroup === nextProps.handleCreateGroup &&
      prevProps.handleOpenDialog === nextProps.handleOpenDialog &&
      prevProps.handlesetgroupInfoPage === nextProps.handlesetgroupInfoPage &&
      prevProps.participants === nextProps.participants
    );
  }
);

export default MessagesList;
